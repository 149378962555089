<template>
  <v-card flat tile class="mx-auto">
    <v-app-bar class="navbar" flat dark fixed height="50" color="rgba(0,0,0,0)"
      style="background-color: rgba(0, 0, 0, 0.3);">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>WILLARD NETWORKS TÜRKİYE</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn @click="$vuetify.goTo('#newcomers', options)" class="d-none d-sm-flex" depressed text>
        YENİ GELENLER
      </v-btn>
      <v-btn @click="$vuetify.goTo('#gameplay', options)" class="d-none d-sm-flex" depressed text>
        HL2RP OYNANIŞ
      </v-btn>
      <v-btn @click="$vuetify.goTo('#lore', options)" class="d-none d-sm-flex" depressed text>
        HİKAYE
      </v-btn>
      <v-btn @click="$vuetify.goTo('#team', options)" class="d-none d-sm-flex" depressed text>
        TAKIM
      </v-btn>
      <v-divider vertical></v-divider>
      <v-btn @click="$vuetify.goTo('#faq', options)" class="d-none d-sm-flex" depressed text>
        SIKÇA SORULANLAR
      </v-btn>
      <v-divider vertical></v-divider>
      <v-btn href="https://discord.gg/WEZ8pdX6gc" target="_blank" class="d-none d-sm-flex" depressed icon>
        <v-icon>mdi-microphone-message</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav>
        <v-list-item-group class="text-center">
          <v-img alt="HL2RP" class="my-4 mx-auto" width="68" src="https://willard.network/hl2rp_imgs/logo.svg"></v-img>
          <v-divider></v-divider>
          <v-list-item @click="$vuetify.goTo('#newcomers', options)" class="mb-0" tile>
            <v-list-item-title class="rounded-0">YENİ GELENLER</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="$vuetify.goTo('#gameplay', options)" class="mb-0" tile>
            <v-list-item-title class="rounded-0">HL2RP OYNANIŞ</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="$vuetify.goTo('#lore', options)" class="mb-0" tile>
            <v-list-item-title class="rounded-0">HİKAYE</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="$vuetify.goTo('#team', options)" class="mb-0" tile>
            <v-list-item-title class="rounded-0">TAKIM</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="$vuetify.goTo('#faq', options)" class="mb-0" tile>
            <v-list-item-title class="rounded-0">SSS</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <div class="d-flex justify-center mt-8">
            <v-btn href="https://discord.gg/WEZ8pdX6gc" target="_blank" class="mx-1" depressed icon>
              <v-icon>mdi-microphone-message</v-icon>
            </v-btn>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-container fluid class="pa-0">
      <Intro />
      <Newcomers />
      <Gameplay />
      <Lore />
      <Dark />
      <Team />
      <Faq />
      <Join />
    </v-container>
  </v-card>
</template>

<script>
import * as easings from 'vuetify/es5/services/goto/easing-patterns'
import Intro from '@/views/Intro.vue'
import Newcomers from '@/views/Newcomers.vue'
import Gameplay from '@/views/Gameplay.vue'
import Lore from '@/views/Lore.vue'
import Team from '@/views/Team.vue'
import Faq from '@/views/Faq.vue'
import Join from '@/views/Join.vue'
export default {
  name: 'Home',
  data: () => ({
    drawer: false,
    duration: 400,
    offset: 0,
    easing: 'easeInOutCubic',
    easings: Object.keys(easings)
  }),
  components: {
    Intro,
    Newcomers,
    Gameplay,
    Lore,
    Team,
    Faq,
    Join
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      }
    }
  }
}
</script>
<style lang="scss">
html,
body {
  background-color: #1c1c1c;
}

@media (max-width: 450px) {
  .navbar {
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
}

.theme--dark.v-navigation-drawer {
  background-color: rgba(54, 54, 54, 0.5) !important;
  backdrop-filter: blur(4px) !important;
}
</style>
