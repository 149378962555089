<template>
  <v-row id="newcomers" class="wrapper overflow-hidden" align="center">
    <v-col class="pl-8 pl-md-3" cols="12" md="5" lg="3" offset-md="1" offset-lg="2">
      <v-card max-width="360" class="content mx-auto transparent" flat>
        <h1>YENİ GELENLER</h1>
        <h2 class="font-italic">ŞEHİR <span class="primary white--text">{{ timerCount }}</span>'{{ turkish }}<br>HOŞ
          GELDİNİZ
        </h2>
        <p>Dünya, Combine olarak adlandırılan bir uzaylı kuvvet tarafından işgal altında. Yeni şehrinize vardınız ve bu
          dünyada hayatta kalmanın bir yolunu bulmalısınız.</p>
        <p>Sisteme ayak uydurmayı mı seçeceksiniz? Zulme karşı gitmeyi mi? Baskı ve yok olmaya karşı
          mücadeleye katılın ya da evrensel birliğe itaat edin.</p>
        <p>Hikaye anlatıcısı sizsiniz, hangi hikayeyi anlatacaksınız?</p>
        <v-btn href="https://discord.gg/WEZ8pdX6gc" target="_blank" class="mt-4 mr-3" outlined>
          <v-icon class="mr-2" small>mdi-microphone-message</v-icon>Discord
        </v-btn>
        <a href="https://www.deviantart.com/xieangel/art/City-17-Streets-836653258" target="_blank"
          class="font-italic text-decoration-none d-block mt-3" style="color: #FFCC00;">Arkaplan tasarımı: XieAngel</a>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>

export default {

  data() {
    return {
      timerCount: 45,
      turkish: 'e'
    }
  },

  watch: {
    timerCount: {
      handler() {
        setTimeout(() => {
          var random = Math.random() * (50 - 1) + 1
          this.timerCount = Math.floor(random)
          var lastDigit = this.timerCount % 10
          if (lastDigit == 1 || lastDigit == 3 || lastDigit == 4 || lastDigit == 5 || lastDigit == 8) {
            this.turkish = 'e'
          } else if (lastDigit == 2 || lastDigit == 7) {
            this.turkish = 'ye'
          } else if (lastDigit == 6) {
            this.turkish = 'ya'
          } else if (lastDigit == 9 || lastDigit == 0) {
            this.turkish = 'a'
          }
        }, 1500)
      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
  },
}


</script>
<style lang="scss">
#newcomers {
  .parallax {
    background-image: url("~@/assets/hl2rp_imgs/city.jpg");

    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.7);
    }
  }
}
</style>
