<template>
  <v-row id="faq" class="wrapper overflow-hidden text-center" align="center" justify="center">
    <v-col cols="12" md="6">
      <v-card class="content transparent" flat>
        <h1>S.S.S.</h1>
        <h2 class="mb-8">Sıkça Sorulan Sorular</h2>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="py-md-5">
              <h3>Willard Networks'ü diğer topluluklardan farklı kılan nedir?</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left secondary--text">
              Birçok farklı arka plana, önceki sunuculara ve benzerine sahip insanların bir topluluğuyuz; HL2:RP'yi şu
              anda olduğundan çok daha iyi bir yer yapmaya çalışıyoruz. Oyunculara daha fazla özgürlük sağlamayı
              amaçlıyoruz, kaderlerini, hikayelerini belirlemelerine, dünyaya etki etmelerine ve dünyanın eylemlerine
              tepki vermesine izin veriyoruz. Oyuncuların kurallarla sıkışıp kalmamalarını ve kısıtlanmış hissetmemelerini
              istemiyoruz. Artık görünmez duvarlar veya bir yere gidemezsiniz diyen nesneler/yazılar yok. Oyuncular şimdi
              hikayeyi yazanlar, personel değil. Siz yaratırsınız, siz karar verirsiniz, siz eğlenirsiniz.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="py-md-5">
              <h3>Sivil fraksiyonu nasıl? Diğer fraksiyonların yapacak çok şeyi var gibi duruyor, standart bir vatandaş
                olarak neler yapabilirim?</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left secondary--text">
              HL2RP'nin ilk başladığı yıllardan beri ortaya çıkan veya yaratılan bazı önemli sorunları çözmeye çalışarak
              perde arkasında büyük ölçüde üzerinde çalıştığımız bir konu vatandaş fraksiyonu. Ancak şunu hemen
              söyleyebiliriz ki, vatandaş fraksiyonunun şehrin tüm yönlerine ve işlevselliğine çokça dahil olduğundan emin
              olmak için elimizden gelenin en iyisini yapıyoruz. Vatandaş olarak şehri sadece gözlemlemekle yetinmeyin!
              Diğer vatandaşlarla bağlar kurun, oyunlar oynayın, sohbet edin, bir şeyler inşa edin, karakterinizi
              geliştirin ve birlikte eğlenin. Şehri, hep birlikte canlı bir hale getirin! Vatandaş fraksiyonu hikayeye ve
              çevreye ciddi derecede değişiklik getirmenizi sağlar, yaptıklarınızın sonuçlarını zamanla göreceksiniz.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="py-md-5">
              <h3>Sunucunun IP'si/Workshop'unu nerede bulabilirim?</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left secondary--text">
              Sunucunun IP'sini (hl2rp.willard.network:27081) sunucu tarayıcınızdan favorilerinize ekleyebilirsiniz.
              Atölye içeriğine ulaşmak için <a href="https://discord.gg/WEZ8pdX6gc/">Discord</a> sunucumuzdaki #workshop
              kanalına göz atabilir veya <a
                href="https://steamcommunity.com/sharedfiles/filedetails/?id=3028452353">buraya</a> tıklayabilirsiniz.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="py-md-5">
              <h3>Sunucu içerisindeki bazı şeyleri göremiyorum/mor-siyah görüyorum. Ne yapmalıyım?
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left secondary--text">
              CS:S içeriklerini her ne kadar az kullanmaya çalışsak da sunucu içerisindeki bazı şeylerin görünmesi için
              CS:S'e sahip ve indirmiş olmanız gerekiyor. CS:S'e sahip değilseniz, CS:S içeriklerini indirmek için harici
              kaynaklara başvurabilirsiniz. Ayrıca atölye içeriğimizi indirdiğinize de emin olun.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>
export default {

}
</script>
<style lang="scss">
#faq {
  .parallax {
    background-image: url("~@/assets/hl2rp_imgs/cityadmin.jpg");

    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.6);
    }
  }
}
</style>
