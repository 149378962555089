<template>
  <v-row id="lore" class="wrapper overflow-hidden" align="center">
    <v-col class="pl-8 pl-md-3" cols="12" md="5" lg="3" offset-md="1" offset-lg="2">
      <v-card max-width="380" class="content mx-auto transparent" flat>
        <h1>HİKAYE</h1>
        <h2 class="font-italic">TOPLULUĞUN OLUŞTURDUĞU BENZERSİZ BİR HİKAYE</h2>
        <p>
          2001 yılında Rezonans Çağlayanı meydana geldi. Xen ve Dünya'yı birleştiren bu korkunç felaket,
          yüz milyonlarca
          insanın ölümüne sebep oldu.
          Yıllar geçti ve insanlık yavaş yavaş Xen'in tehdidi
          altında çürüdü. Combine Dünya'ya saldırdığında, soğuk, kalpsiz egemenliklerini kurarak Dünya'yı işgal ettiler.
        </p>
        <p>
          Dünya'nın her yerinde milyarlarca insan, Combine rejiminin hüküm sürdüğü şehirlerde toplandı.
          Hem insanlar hem de Vortigaunt'lar, Combine'ın neden olduğu bu totaliter kabusu yaşıyor.
        </p>
        <p>
          Combine'ın gelişinin on yılını geride bıraktık. Sene 2017. Onların yönetiminde, Combine'ın iradesi her şeyin
          üstünde.
          Böylesine bir güç karşısında bile, insanların ve Vortigaunt'ların kalbinde direnişin alevi yanıyor. Gelecek,
          cesur ve hırslı olanlar tarafından şekillendirilecek, sonucu her ne olursa olsun.
        </p>
      </v-card>
    </v-col>
    <div class="parallax"></div>
  </v-row>
</template>
<script>
export default {
}
</script>
<style lang="scss">
#lore {
  .parallax {
    background-image: url("~@/assets/hl2rp_imgs/globe.jpg");

    &:before {
      content: "";
      background-color: rgba(34, 32, 64, 0.7);
    }
  }
}
</style>
